import(/* webpackMode: "eager" */ "/app/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/app/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Inter_Tight\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\",\"variable\":\"--font-inter-tight\"}],\"variableName\":\"interTight\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Geist_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\",\"variable\":\"--font-geist-mono\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../styles/fonts/EditorsNote/EditorsNote-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../styles/fonts/EditorsNote/EditorsNote-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-editors-note\"}],\"variableName\":\"editorsNote\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../styles/fonts/EditorsNoteText/EditorsNoteTextDemoTESTINGUSEONLY-Italic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../styles/fonts/EditorsNoteText/EditorsNoteTextDemoTESTINGUSEONLY-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-editors-note-text\"}],\"variableName\":\"editorsNoteText\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_analytics/page-view-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/app/src/app/_analytics/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPrivyProvider"] */ "/app/src/app/_auth/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/app/src/app/components/navbar/navbar-temp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/app/components/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
